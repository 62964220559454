import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, RadioGroup,
  Stack,
  Radio,FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast, Tooltip, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {  MdAdd, MdFilePresent, MdFileUpload, MdPeople, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function PlacementExample({axiosReferenciass}) {
    const [placement] = useState('right')
    const [referencias, setReferencias] = useState([])
    const [produtos, setProdutos] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const [eventos, setEVentos] = useState([])
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()
    const navigate =  useNavigate()
    
    
    async function axiosReferencias(){
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      
      const referencia = await AXIOS.get(`/registro-produtos-cliente/entidade/${entidade}`)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem[0].registo)
      }
      
          
  }

  const gruposEfetchAsync = async () =>{
    const entidade = JSON.parse(
      document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        ? document.cookie
            .split(";")
            .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
            .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
        : "{}"
    ).mensagem.numero_entidade;
    try {
      const res = await AXIOS.get(`/afiliados/identificacao/afiliados/clientes/entidade/${entidade}`)
      if (res.data.status == "sucesso"){
          setEVentos(res.data.mensagem)
      }else {
        setEVentos([])
      }
    }catch (error) {
      navigate('/sem-comunicacao')
    }
      
  } 
  
  
    
    const formik = useFormik({
      initialValues:{
        num_referencia:'',
        data_limite_pagamento:'',
        estado_atm:'Activo',
        dia_inicio:'',
        dia_final:'',
        grupo_identificacao:''
      },
      onSubmit: async (values)=> {
      
      
        const res =  await AXIOS.post(`/relatorios`,
        {
          "entidade":user.numero_entidade,
          "tipo": "Referencias",
          "campos": values
      }) 

        toast({
          title: 'Gerar relatorio',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 3000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{
            setIsOpen(false)
            formik.resetForm()
          },1000)
          
          
          
          setTimeout(()=>{
            toast({
              title: 'Gerar relatorio',
              description: "Gerado com sucesso",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferenciass()
          },3500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Gerar relatorio',
              description: "Aconteceu, um problema",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        axiosReferencias() 
      },
    })

    const formikPag = useFormik({
      initialValues:{
        referencia_do_servico:'',
        montante_da_operacao:'',
        MFT:'',
        PRT:'',
        estado_pagamento:'',
        dia_inicio:'',
        dia_final:'',
        Identificacao_Log_EGR:'',
        grupo_identificacao:''
      },
      onSubmit: async (values)=> {
      
      
        const res =  await AXIOS.post(`/relatorios`,
        {
          "entidade": user.numero_entidade,
          "tipo": "Pagamentos",
          "campos": values
      }) 

        toast({
          title: 'Gerar relatorio',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 3000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{
            setIsOpen(false)
            formik.resetForm()
          },1000)
          
          
          
          setTimeout(()=>{
            toast({
              title: 'Gerar relatorio',
              description: "Gerado com sucesso",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferenciass()
          },3500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Gerar referência',
              description: "Aconteceu, um problema",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        axiosReferencias() 
      },
    })

    const formikPeriodo = useFormik({
      initialValues:{
        referencia_do_servico:'',
        montante_da_operacao:'',
        dia_inicio:'',
        dia_final:'',
        Identificacao_Log_EGR:''
      },
      onSubmit: async (values)=> {
      
      
        const res =  await AXIOS.post(`/relatorios`,
        {
          "entidade":user.numero_entidade,
          "tipo": "Periodos",
          "campos": values
      }) 

        toast({
          title: 'Gerar relatorio',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 3000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{
            setIsOpen(false)
            formik.resetForm()
          },1000)
          
          
          
          setTimeout(()=>{
            toast({
              title: 'Gerar relatorio',
              description: "Gerado com sucesso",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferenciass()
          },3500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Gerar referência',
              description: "Aconteceu, um problema",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        axiosReferencias() 
      },
    })

    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      axiosReferencias();      
      gruposEfetchAsync();
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }
      axiosReferencias()
      
  },[])
    return (
      <>
        <Button leftIcon={<MdFilePresent/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          {t("Solicitar")}
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Gerar Relatorios")}</DrawerHeader>
            <DrawerBody>

              <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Referências</Tab>
                  <Tab>Pagamentos</Tab>
                  <Tab>Periodos contabilistico</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <form onSubmit={formik.handleSubmit}>
                    <Box bg='white'>
                      <FormControl mt={4}>
                          <FormLabel>{t("Referência")}</FormLabel>
                            <Input type='text'  name='num_referencia' onChange={formik.handleChange} value={formik.values.num_referencia} />
                      </FormControl>
                      <Flex>
                        <FormControl mt={2}>
                              <FormLabel>{t("Data de inicio de criação")}</FormLabel>
                              <Input type='date'  name='dia_inicio'  required onChange={formik.handleChange} value={formik.values.dia_inicio} />
                        </FormControl>
                        <FormControl mt={2} ml={4}>
                              <FormLabel>{t("Data de final de criação")}</FormLabel>
                              <Input type='date' name='dia_final' onChange={formik.handleChange} required value={formik.values.dia_final} />
                        </FormControl>
                      </Flex>
                      <FormControl mt={2}>
                          <FormLabel>{t("Data limite de pagamento")}</FormLabel>
                            <Input type='date'  name='data_limite_pagamento' onChange={formik.handleChange} value={formik.values.data_limite_pagamento} />
                      </FormControl>
                      <Flex>
                          <FormControl mt={2}>
                                <FormLabel>{t("Estado")}</FormLabel>
                                <Select required name='estado_atm' onChange={formik.handleChange} value={formik.values.estado_atm}>
                                  <option value="80">{t("Activo")}</option>
                                  <option value="82">{t("Inactivo")}</option>
                                  <option value="82">{t("Á Processar")}</option>
                              </Select>
                          </FormControl>
                          <FormControl mt={2} ml={4}>
                            <FormLabel>{t("Grupo de indetificação")}</FormLabel>
                            <Flex direction="row" alignItems="center">
                              <Select name='grupo_identificacao' onChange={formikPag.handleChange} value={formikPag.values.grupo_identificacao}>
                                <option selected value="">Todas</option>
                                {eventos.map(fl => <option value={fl.id_identificacao_afiliados}>{fl.nome_identificacao}</option>)}
                              </Select>
                            </Flex>
                          </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdFileUpload/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Gerar")}</Button>
                  
                  </Box> 
                    </form>
                  </TabPanel>
                  <TabPanel>
                    <form onSubmit={formikPag.handleSubmit}>
                    <Box bg='white'>
                      <FormControl mt={4}>
                          <FormLabel>{t("Referência")}</FormLabel>
                            <Input type='text'  name='referencia_do_servico' onChange={formikPag.handleChange} value={formikPag.values.referencia_do_servico} />
                      </FormControl>
                      <Flex>
                        <FormControl mt={2}>
                              <FormLabel>{t("Data de inicio de criação")}</FormLabel>
                              <Input type='date'  name='dia_inicio'  required onChange={formikPag.handleChange} value={formikPag.values.dia_inicio} />
                        </FormControl>
                        <FormControl mt={2} ml={4}>
                              <FormLabel>{t("Data de final de criação")}</FormLabel>
                              <Input type='date' name='dia_final' onChange={formikPag.handleChange} required value={formikPag.values.dia_final} />
                        </FormControl>
                      </Flex>
                      <FormControl mt={2}>
                          <FormLabel>{t("Montante")}</FormLabel>
                            <Input type='number'  name='montante_da_operacao' onChange={formikPag.handleChange} value={formikPag.values.montante_da_operacao} />
                      </FormControl>
                      <Flex>
                          <FormControl mt={2}>
                                <FormLabel>{t("Notificação de pagamento")}</FormLabel>
                                <Select name='PRT' onChange={formikPag.handleChange} value={formikPag.values.PRT}>
                                  <option value="">{t("QUALQUER")}</option>
                                  <option>{t("ACEITE")}</option>
                                  <option>{t("NÃO ADIRIU")}</option>
                              </Select>
                          </FormControl>
                          <FormControl mt={2} ml={4}>
                                <FormLabel>{t("Concliação Bancária")}</FormLabel>
                                <Select name='MFT' onChange={formikPag.handleChange} value={formikPag.values.MFT}>
                                  <option value="">{t("QUALQUER")}</option>
                                  <option>{t("Á AGUARDAR")}</option>
                                  <option>{t("CONCLUIDO")}</option>
                                  <option>{t("Á Processar")}</option>
                              </Select>
                          </FormControl>
                          <FormControl mt={2} ml={4}>
                                <FormLabel>{t("Estado verificado")}</FormLabel>
                                <Select name='estado_pagamento' onChange={formikPag.handleChange} value={formikPag.values.estado_pagamento}>
                                  <option value="">{t("QUALQUER")}</option>
                                  <option>{t("PENDENTE")}</option>
                                  <option>{t("FINALIZADO")}</option>
                                  <option>{t("ANULADO")}</option>
                              </Select>
                          </FormControl>
                      </Flex>
                      <Flex>
                        <FormControl mt={2}>
                                <FormLabel>{t("Número do periodo contabilistico")}</FormLabel>
                                <Input type='number' name='Identificacao_Log_EGR' onChange={formikPag.handleChange} value={formikPag.values.Identificacao_Log_EGR} />
                          </FormControl>
                          <FormControl mt={2} ml={4}>
                            <FormLabel>{t("Grupo de indetificação")}</FormLabel>
                            <Flex direction="row" alignItems="center">
                              <Select name='grupo_identificacao' onChange={formikPag.handleChange} value={formikPag.values.grupo_identificacao}>
                                <option selected value="">Todas</option>
                                {eventos.map(fl => <option value={fl.id_identificacao_afiliados}>{fl.nome_identificacao}</option>)}
                              </Select>
                            </Flex>
                          </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdFileUpload/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Gerar")}</Button>
                  
                  </Box> 
                    </form>
                  </TabPanel>
                  <TabPanel>
                    <form onSubmit={formikPeriodo.handleSubmit}>
                      <Box bg='white'>
                        {/* <FormControl mt={4}>
                            <FormLabel>{t("Referência")}</FormLabel>
                              <Input type='text'  name='referencia_do_servico' onChange={formikPeriodo.handleChange} value={formikPeriodo.values.referencia_do_servico} />
                        </FormControl> */}
                        <Flex>
                          <FormControl mt={2}>
                                <FormLabel>{t("Data de inicio de criação")}</FormLabel>
                                <Input type='date'  name='dia_inicio' onChange={formikPeriodo.handleChange} value={formikPeriodo.values.dia_inicio} />
                          </FormControl>
                          <FormControl mt={2} ml={4}>
                                <FormLabel>{t("Data de final de criação")}</FormLabel>
                                <Input type='date' name='dia_final' onChange={formikPeriodo.handleChange} value={formikPeriodo.values.dia_final} />
                          </FormControl>
                        </Flex>
                        <Flex>
                          <FormControl mt={2}>
                              <FormLabel>{t("Montante")}</FormLabel>
                              <Input type='number'  name='montante_da_operacao' onChange={formikPeriodo.handleChange} value={formikPeriodo.values.montante_da_operacao} />
                          </FormControl>
                          <FormControl mt={2} ml={4}>
                                <FormLabel>{t("Número do periodo contabilistico")}</FormLabel>
                                <Input type='number' name='Identificacao_Log_EGR' onChange={formikPeriodo.handleChange} value={formikPeriodo.values.Identificacao_Log_EGR} />
                          </FormControl>
                        </Flex>
                      
                      <Button leftIcon={<MdFileUpload/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Gerar")}</Button>
                  
                      </Box> 
                    </form>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>

          </DrawerContent>
        </Drawer>
      </>
    )
  }