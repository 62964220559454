import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Checkbox,
  Center,
  Spinner,
  useToast,
  Input,NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select
} from "@chakra-ui/react";
import {
  MdCached,
  MdClose,
  MdArrowBack,
  MdArrowForward,
  MdEdit,
  MdUpgrade,
  MdError,
  MdOutlineFileDownload
} from "react-icons/md";
import { FcProcess } from "react-icons/fc";
import { HiStatusOnline, HiStatusOffline } from "react-icons/hi";
import {
  BotaoTop,
  Drawer,
  DrawerFiltrosReferencias,
  DrawerReferenciasAdm,
  DrawerRelatorioEntidades,
  Loader,
  ModalGerarFileRef,
  ModalRemoveRef,
  ModalRemoveRelatorios,
  ModalReportReferencias,
  ModalReportReferenciasGerarXML,
  ModalReportReferenciasGerarXMLAdm,
  ModalViewReference,
  NavBar,
  NavBarAdm,
  SideBarMenu,
  SideBarMenuAdm
} from "../../Components/index";

import AXIOS from '../../Config/axiosConfig'
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CONFIG from "../../Config";
import { RiPassExpiredFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function Referencias() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation()
  const {id} = useParams();
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.ADM))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.ADM))
        .split(CONFIG.COOKIE_SESSION.ADM)[1]
    : "{}";
  const [user, setUser] = useState({});
  const [checkBox, setCheckBox] = useState([]);
  const [loader, setLoader] = useState(true);
  const toast = useToast();
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')

  async function axiosReferencias() {
    try {
      const entidade = id

      const referencia = await AXIOS.get(
        `/relatorios/entidade/${entidade}`
      );
      
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite)
      }
      setLoader(true);
    } catch (error) {
      // navigate("/sem-comunicacao");
    }
  }

  const excluirRef = async (ref) => {
    try {
    
      if((ref.estado_atm == "Á Processar") || (ref.estado_atm == "Activo")){
          
          toast({
            title: "Exclusão de referência",
            description: "Não podemos excluir a sua referencia com este estado em processo ou activo",
            status: "error",
            duration: 5000,
            isClosable: true
          });
          
          return
      }
      
      const refs = referencias.filter(
        (r) => r.id_relatorio != ref.id_relatorio
      );
      
      const res = await AXIOS.delete(
        `/relatorios/${ref.id_relatorio}`
      );
      if (res.data.status == "sucesso") {
        setReferencias(refs);
        toast({
          title: "Exclusão de referência",
          description: "Conseguimos excluir a referência com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: "Exclusão de referência",
          description: "Não conseguimos excluir a referência",
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }

      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
      toast({
        title: "Alerta",
        description: "Não conseguimos ter comunicação! Verifique sua internet",
        status: "error"
      });
    }
  };

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.id_usuarios) {
      setUser(value.mensagem);
    } else {
      navigate("/adm/login");
    }
  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const selectdItem = (evt) => {
    const tipo = evt.target.value.toLocaleLowerCase();
    const modified = referencias.map((item) => {
      return {
        ...item,
        num_referencia: item.num_referencia,
        referencia_do_montante: item.referencia_do_montante,
        data_limite_pagamento: item.data_limite_pagamento,
        data_inicio_de_pagamento: item.data_inicio_de_pagamento,
        codigo_de_processamento:
          item.codigo_de_processamento == 80 ? "Activo" : "Inactivo",
        registo_produto: item.registo_produto
      };
    });
    const filtered = modified.filter(
      (item) =>
        JSON.stringify(item)
          .toLocaleLowerCase()
          .replaceAll("T", " ")
          .replaceAll("Z", "")
          .replaceAll("-", "/")
          .indexOf(tipo) > -1
    );
    setReferenciasFilter(filtered);
  };

  const buscarComPaginacao  = async function(){
    try {
      setLoader(false)
      const entidade = id
      const referencia = await AXIOS.get(`/relatorios/entidade/${entidade}`)
      

      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem) 
          setPagina(referencia.data.registros.pagina_actual) 
          setPaginas(referencia.data.registros.paginas) 
          setQuantidade(referencia.data.registros.limite) 
        }
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
  }
 
  async function preencherNaTabela(referencia) {
    try {

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }
  
let permissao = user?.pagina_relatorio == "true";

if(user?.pagina_relatorio) permissao = user?.pagina_relatorio == "true"
else permissao = user.todas_areas ? true : false;

  return (
    <>
      <Flex color="white" bg="gray.300" style={{minHeight:'100vh'}} direction="row">
        <SideBarMenuAdm />
        <BotaoTop/>
        <Box flex={1}>
          <NavBarAdm />
          {
          permissao

          ?
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
          >
            <Box px={10}>
              <Text
                fontSize={50}
                color="blackAlpha.600" 
                fontWeight="bold"
                mb={8}
                mt={5}
              >
                Entidade {id} | Relatórios
              </Text>
              <Flex alignItems="center" justifyContent="space-between">
                <Box>
                <DrawerRelatorioEntidades axiosReferenciass={axiosReferencias} entidade={id}/>
                <Button
                      bg="blue.400"
                      _hover={{ backgroundColor: "blue.600" }}
                      ml={1}
                      onClick={axiosReferencias}
                      leftIcon={<MdUpgrade />}
                      color="white"
                    >
                      {t("Actualizar lista")}
                </Button>
                </Box>
                <Flex color="gray.500" alignItems="center">
                  <Text></Text>{" "}
                  <Input
                    w="sm"
                    type="search"
                    bg="white"
                    placeholder="Pesquise ..."
                    ml={1}
                    onChange={selectdItem}
                  />
                  <NumberInput
                  name='pagina'
                  onChange={(valueString) => setPagina(parseInt(valueString))}
                  value={pagina}
                  max={paginas}
                  min={1}
                  width={120}
                  ml={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                </Select>
                <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
                </Flex>
              </Flex>

              <Text mt={2} color="black" fontWeight="bold">
                Itens selecionados: {checkBox.length}
              </Text>
              {loader ? (
                 <TableContainer mt={5} bg="white">
                 <Table variant='striped' colorScheme='gray' boxShadow="md">
                   <TableCaption>
                     <ReactPaginate
                          breakLabel="..."
                          nextLabel={<MdArrowForward/>}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          breakClassName="list-group-item"
                          previousLabel={<MdArrowBack/>}
                          renderOnZeroPageCount={null}
                           />
                   </TableCaption>
                   <Thead p={10}>
                     <Tr bg="gray.600">
                       <Th py={6} color="gray.300">{t("#")}</Th>
                       <Th py={6} color="gray.300">{t("Efeito")}</Th>
                       <Th py={6} color="gray.300">{t("Gerado em")}</Th>
                       <Th py={6} color="gray.300">{t("Ficheiro")}</Th>
                       <Th py={6} color="gray.300">{t("Download")}</Th>
                       <Th py={6} color="gray.300">{t("Acção")}</Th>
                     </Tr>
                   </Thead>
                   <Tbody>
                     {currentItems.map(el =>
                         <Tr color="blackAlpha.700">
                           <Td>{el.id_relatorio}</Td>
                           <Td>{el.para}</Td>
                           <Td>{new Date(el.gerado_quando).toLocaleDateString()}</Td>
                           <Td>{el.file_gerado}</Td>
                           <Td><a target='_blank' download={`${CONFIG.HOST_API}/reports/`+el.file_gerado} href={`${CONFIG.HOST_API}/reports/`+el.file_gerado}><MdOutlineFileDownload size={30} /></a></Td>
                           <Td>
                           <ModalRemoveRelatorios
                                   registo={el}
                                   excluirRef={excluirRef}
                                 />
                           </Td>
                         </Tr>
                     )}
                   </Tbody>
                 </Table>
               </TableContainer>
              ) : (
                <Center mt={10}>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                Todos direitos reservados &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
          :   

        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
        }
        </Box>
      </Flex>
    </>
  );
}

export default Referencias;
