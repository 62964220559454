import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast, Radio, Stack, RadioGroup } from '@chakra-ui/react'
import {  MdAdd, MdOutlinePassword, MdPeople, MdRemoveRedEye} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'

export default function PlacementExample({axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const [gpo, setGPO] = useState("Referências") 
    const [tipoSenha, setTipoSenha] = useState('password')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()


    const verSenha = () => {

      if(tipoSenha == "password") {
        setTipoSenha('text')
      }else{
        setTipoSenha('password')
      }
    }
    

    const formik = useFormik({
      initialValues:{
        nome_empresa:'',
        nif:'',
        contacto:'',
        email: '',
        senha: '',
        numero_entidade: '',
        num_padrao_referencias: '',
        responsavel: '',
        montante_maximo_pagamento: '19999999',
        montante_minimo_pagamento: '10',
        versao_mensagem_PRT: '04', 
        parametrizado_como:'Novo',
        validacao_referencias:'Realtime',
        ultimo_ficheiro_enviado_a_emis:'00000000000',
        gpo_numero_comerciante: null,
        gpo_numero_cartao: null,
        gpo_numero_banco: null,
        gpo_numero_POS: null,
        gpo_numero_establecimento: null
      },
      onSubmit: async (values)=> {

        // if(!/(\.)/g.test(values.montante_minimo_pagamento)){

        //   toast({
        //     title: 'Actualização de entidade',
        //     description: "Seu montante minimo de ser decimal",
        //     status: 'error',
        //     duration: 5000,
        //     isClosable: true,
        //   })
  
        //   return
        // }
  
        // if(!/(\.)/g.test(values.montante_maximo_pagamento)){
  
        //   toast({
        //     title: 'Actualização de entidade',
        //     description: "Seu montante maximo de ser decimal",
        //     status: 'error',
        //     duration: 5000,
        //     isClosable: true,
        //   })
  
        //   return
        // }

        // alert(JSON.stringify(loginValue))
        const res =   await AXIOS.post(`/clientes`,values, {headers: { criador: user.id_usuarios}})
        
        toast({
          title: 'Criação de entidade',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 4000,
          isClosable: true,
        })

        if(res.data.status === "sucesso"){          
          
          
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Criação de entidade',
              description: res.data.mensagem,
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferencias()
          },5000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Criação de entidade',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        
      },
    })
    
    const validarDigitos = (evt) =>{
      const keys = ["8","46","48","49","50","51","52","53","54","55","56","57"]
      const find = keys.find(key => key == evt.keyCode)
      if(!find) evt.target.value = evt.target.value.replace(evt.target.value.substr(-1),'')
      
      
  }
    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      if(loginValue){
       const value = JSON.parse(loginValue)
       setUser(value.mensagem)
      }
      
  },[])
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.900' color="white" onClick={()=>setIsOpen(true)}>
          Adcionar
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>Criar entidades</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <RadioGroup my={10} onChange={setGPO} value={gpo}>
                    <Stack direction='row' justifyContent="center">
                      <Radio value="Referências">Pagamento por referência</Radio>
                      <Radio value="GPO">Gateway de pagamentos online (GPO)</Radio>
                    </Stack>
                  </RadioGroup>
                  <Box bg='white'>
                   <FormControl>
                        <FormLabel>Empresa</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='nome_empresa' onChange={formik.handleChange} value={formik.values.nome_empresa} />
                         </Flex>
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel>NIF</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='text' name='nif'  required  onChange={formik.handleChange} value={formik.values.nif} />
                        </Flex>
                    </FormControl>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl mt={2}>
                          <FormLabel>Contacto</FormLabel>
                          <Input type='tel' name='contacto' onChange={formik.handleChange} required value={formik.values.contacto} />
                      </FormControl>
                      <FormControl mt={2} ml={2}>
                          <FormLabel>Email</FormLabel>
                          <Input type='email' name='email'  required onChange={formik.handleChange} value={formik.values.email} />
                      </FormControl>
                    </Flex>
                   <FormControl mt={2}>
                        <FormLabel>Senha</FormLabel>
                        <Flex>
                          <Input type={tipoSenha} name='senha' required onChange={formik.handleChange} value={formik.values.senha} />
                          <Button ml={2} onClick={verSenha} leftIcon={tipoSenha == "password" ? <MdRemoveRedEye/> : <MdOutlinePassword/> } />
                        </Flex>
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel>Responsável</FormLabel>
                        <Input type='text' name='responsavel' required onChange={formik.handleChange}  value={formik.values.responsavel} />
                    </FormControl>
                    {gpo == "Referências" && <>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={2}>
                            <FormLabel>Tamanho das Referências</FormLabel>
                            <Input type='number' min={9} max={15} name='num_padrao_referencias' required  onChange={formik.handleChange} value={formik.values.num_padrao_referencias} />
                        </FormControl>
                        <FormControl mt={2} ml={2}>
                            <FormLabel>Codigo de entidade</FormLabel>
                            <Input type='text' minLength={5} maxLength={gpo ? 10 : 5} name='numero_entidade' onKeyUp={validarDigitos}  onChange={formik.handleChange} value={formik.values.numero_entidade} />
                        </FormControl>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={2}>
                            <FormLabel>Montante Minimo</FormLabel>
                            <Input type='number' min={10} max={19999999} name='montante_minimo_pagamento' required  onChange={formik.handleChange} value={formik.values.montante_minimo_pagamento} />
                        </FormControl>
                        <FormControl mt={2} ml={2}>
                            <FormLabel>Montante Maximo</FormLabel>
                            <Input type='number' min={100} max={19999999} name='montante_maximo_pagamento' onChange={formik.handleChange} value={formik.values.montante_maximo_pagamento} />
                        </FormControl>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={2}>
                            <FormLabel>Versão da mensagem</FormLabel>
                            <Select required name='versao_mensagem_PRT' onChange={formik.handleChange} value={formik.values.versao_mensagem_PRT}>
                              <option value="03">Versão 3</option>
                              <option value="04">Versão 4</option>
                              <option value="05">Versão 5</option>
                            </Select>
                        </FormControl>
                        <FormControl mt={2}  ml={2}>
                            <FormLabel>Parametrizado como</FormLabel>
                            <Select required name='parametrizado_como' onChange={formik.handleChange} value={formik.values.parametrizado_como}>
                              <option value="Novo">Novo</option>
                              <option value="Migrado">Migrado</option>
                            </Select>
                        </FormControl>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={2}>
                            <FormLabel>Validação de referencias e pagamentos</FormLabel>
                            <Select required name='validacao_referencias' onChange={formik.handleChange} value={formik.values.validacao_referencias}>
                              <option value="Realtime">Realtime</option>
                              <option value="Ficheiros">Ficheiros</option>
                            </Select>
                        </FormControl>
                        {((formik.values.validacao_referencias == "Ficheiros") && (formik.values.parametrizado_como == "Migrado")) && <FormControl mt={2} ml={2}>
                            <FormLabel>Ultimo ficheiro enviado</FormLabel>
                            <Input type='text' defaultValue="00000000000" name='ultimo_ficheiro_enviado_a_emis' onChange={formik.handleChange} value={formik.values.ultimo_ficheiro_enviado_a_emis} />
                        </FormControl>}
                      </Flex>
                    </>}
                    {gpo == "GPO" && <>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={2}>
                            <FormLabel>Número de Comerciante</FormLabel>
                            <Input type='text' minLength={5} maxLength={10} name='gpo_numero_comerciante' required onChange={formik.handleChange} value={formik.values.gpo_numero_comerciante} />
                        </FormControl>
                        <FormControl mt={2} ml={2}>
                            <FormLabel>Número de Establecimento</FormLabel>
                            <Input type='text' minLength={1} maxLength={15} name='gpo_numero_establecimento' required  onChange={formik.handleChange} value={formik.values.gpo_numero_establecimento} />
                        </FormControl>
                        <FormControl mt={2} ml={2}>
                            <FormLabel>Número de Terminais (POS)</FormLabel>
                            <Input type='text' minLength={1} maxLength={3} name='gpo_numero_POS' required onChange={formik.handleChange} value={formik.values.gpo_numero_POS} />
                        </FormControl>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={2}>
                            <FormLabel>Número do Cartão</FormLabel>
                            <Input type='text' minLength={1} maxLength={10000000} name='gpo_numero_cartao' required  onChange={formik.handleChange} value={formik.values.gpo_numero_cartao} />
                        </FormControl>
                        <FormControl mt={2} ml={2}>
                            <FormLabel>Número do Banco</FormLabel>
                            <Input type='text' minLength={1} maxLength={10000000} name='gpo_numero_banco' onChange={formik.handleChange} value={formik.values.gpo_numero_banco} />
                        </FormControl>
                      </Flex>
                    </>}

                    <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>Criar</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }